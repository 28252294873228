<template>
    <app-page no-container>
        <template #page-header>
            <page-header name="Secrets" :loading="loading" v-model="search">
                <e-button-confirm @confirmed="downloadSecretsFile" class="mb-2 mr-1" variant="secondary">Download secrets<i class="fas fa-download ml-1"></i></e-button-confirm>
                <b-btn v-b-modal:import-secrets-modal class="mb-2 mr-1" variant="secondary">Import secrets<i class="fas fa-file-import ml-1"></i></b-btn>
                <b-btn v-if="!showPasswords" @click="showPasswords = true" class="mb-2 mr-1" variant="secondary">Show passwords<i class="fas fa-eye ml-1"></i></b-btn>
                <b-btn v-else @click="showPasswords = false" class="mb-2 mr-1" variant="secondary">Hide passwords<i class="fas fa-eye-slash ml-1"></i></b-btn>
            </page-header>
        </template>
        <b-overlay class="tree-container" :show="loading" rounded="sm">
            <vue-tree-list
                @add-node="addNode"
                @click="() => {}"
                @change-name="renameFolder"
                @delete-node="() => {}"
                :model="secretsTree"
                :default-expanded="false">
                <template #leafNameDisplay="slotProps">
                    <template v-if="slotProps.model.isLeaf">
                        <b-row>
                            <b-col>
                                <b-input-group>
                                    <b-input :value="slotProps.model.name" :class="{'bg-warning': slotProps.model.warn}" readonly></b-input>
                                    <b-input-group-append>
                                        <b-btn @click="copySecretName(slotProps.model)">
                                            <i class="fas fa-copy"></i>
                                        </b-btn>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-col>
                            <b-col>
                                <b-input-group>
                                    <b-textarea v-model="slotProps.model.value" class="secret-value" :class="{blur: !showPasswords}" @input="secretsEdited[slotProps.model.path] = true"></b-textarea>
                                    <b-input-group-append>
                                        <b-btn @click="copy(slotProps.model.value)">
                                            <i class="fas fa-copy"></i>
                                        </b-btn>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-col>
                            <b-col cols="1">
                                <b-btn variant="success" class="mr-1" v-if="secretsEdited[slotProps.model.path]" @click="save(slotProps.model)">
                                    <i class="fas fa-save"></i>
                                </b-btn>
                                <e-button-confirm @confirmed="deleteSecret(slotProps.model)">
                                    <i class="fas fa-trash"></i>
                                </e-button-confirm>
                            </b-col>
                        </b-row>
                    </template>
                    <template v-else>
                        <span>{{ slotProps.model.name }}</span>
                    </template>

                </template>
                <span class="icon" slot="addTreeNodeIcon"><i class="fas fa-folder-plus mr-1"></i></span>
                <span class="icon" slot="addLeafNodeIcon"><i class="fas fa-plus mr-1"></i></span>
                <span class="icon" slot="editNodeIcon"><i class="fas fa-edit mr-1"></i></span>
                <span class="icon" slot="delNodeIcon"><i class="fas fa-trash mr-1"></i></span>
                <span class="icon" slot="leafNodeIcon"><i class="fas fa-key mr-1"></i></span>
                <span class="icon" slot="treeNodeIcon"><i class="fas fa-folder mr-1"></i></span>
            </vue-tree-list>
        </b-overlay>

        <b-modal id="import-secrets-modal" ref="import-secrets-modal" title="Import secrets" size="lg" scrollable>
            <b-form-group label="Import from">
                <b-select v-model="clusterToImport">
                    <option v-for="cluster in $store.state.clusters" :value="cluster.id" :key="cluster.id">{{ cluster.id.toUpperCase() }}</option>
                </b-select>
            </b-form-group>

            <b-form-group label="Default import method">
                <b-select v-model="defaultImportOption">
                    <option value="import">Import</option>
                    <option value="import_with_value">Import value</option>
                    <option value="do_not_import">Do not import</option>
                </b-select>
            </b-form-group>

            <div class="text-center">
                <e-button-async variant="primary" @async-click="previewImport">Preview Import <i class="fas fa-magnifying-glass-arrows-rotate"></i></e-button-async>
            </div>

            <b-list-group v-if="secretsToImport.length > 0" class="mt-2">
                <b-list-group-item v-for="secret of secretsToImport" :key="secret.path">
                    <b-row>
                        <b-col cols="5">
                            <b-select v-model="secret.import">
                                <option value="import">Import</option>
                                <option value="import_with_value">Import value</option>
                                <option value="do_not_import">Do not import</option>
                            </b-select>
                        </b-col>
                        <b-col>
                            <span>{{ secret.path }}</span>
                        </b-col>
                    </b-row>
                </b-list-group-item>
            </b-list-group>

            <template #modal-footer>
                <e-button-async variant="primary" @async-click="importSecrets" :disabled="secretsToImport.length === 0">
                    Import
                    <i class="fas fa-save"></i>
                </e-button-async>
            </template>
        </b-modal>

        <b-modal v-model="secretCreationVisible" title="Create a secret">
            <b-form-group label="Folder name" v-if="newSecret.create_folder">
                <b-input-group>
                    <b-input type="text" v-model="newSecret.folder_name" placeholder="FOLDER_NAME/SUB_FOLDER"/>
                </b-input-group>
            </b-form-group>

            <b-form-group label="Secret name">
                <b-input v-model="newSecret.name" placeholder="SECRET_NAME"/>
            </b-form-group>

            <b-form-group label="Secret value">
                <b-textarea v-model="newSecret.value"></b-textarea>
            </b-form-group>

            <template #modal-footer>
                <b-btn variant="default" @click="secretCreationVisible = false">Cancel</b-btn>
                <b-btn variant="primary" @click="createNewSecret">
                    Create
                    <i class="fas fa-save"></i>
                </b-btn>
            </template>
        </b-modal>
    </app-page>
</template>

<script>
import Network from "../../vue-components/helpers/Network.js";
import {VueTreeList, Tree} from 'vue-tree-list';
import EButtonConfirm from "../../vue-components/components/e-button-confirm.vue";
import EButtonAsync from "../../vue-components/components/e-button-async.vue";

export default {
    name: `secrets`,
    components: {EButtonAsync, EButtonConfirm, VueTreeList},
    data() {
        return {
            loading: true,
            secrets: [],
            showPasswords: false,
            secretCreationVisible: false,
            newSecret: {
                create_folder: false,
                name: ``,
                value: ``
            },
            secretsEdited: {},
            clusterToImport: `dev`,
            secretsToImport: [],
            defaultImportOption: `import`,
            search: ``
        }
    },
    computed: {
        filteredSecrets() {
            if (!this.search) {
                return this.secrets;
            }

            return this.secrets.filter(s => s.path.toLowerCase().includes(this.search.toLowerCase()));
        },
        secretsTree() {
            if (!this.filteredSecrets) {
                return new Tree([])
            }

            let allFolders = {
                '/': {
                    id: `/`,
                    path: `/`,
                    parent_path: null,
                    name: `/`,
                    children: [],
                    dragDisabled: true,
                    editNodeDisabled: true,
                    delNodeDisabled: true
                }
            }

            for (const secret of this.filteredSecrets) {
                if (!allFolders.hasOwnProperty(secret.parent_path)) {
                    const tmp = secret.parent_path.split(`/`);
                    allFolders[secret.parent_path] = {
                        id: secret.parent_path,
                        name: tmp.pop(),
                        path: secret.parent_path,
                        parent_path: secret.parent_path.split(`/`).slice(0, -1).join(`/`),
                        children: [],
                        dragDisabled: true,
                        editNodeDisabled: true,
                        delNodeDisabled: true
                    };
                }

                allFolders[secret.parent_path].children.push({
                    id: secret.path,
                    parent_path: secret.parent_path,
                    path: secret.path,
                    name: secret.name,
                    value: secret.value,
                    warn: this.secretValueShouldWarn(secret),
                    isLeaf: true,
                    dragDisabled: true,
                    editNodeDisabled: true,
                    delNodeDisabled: true
                });
            }

            const allFoldersArray = Object.values(allFolders);
            allFoldersArray.reverse();
            for (const folder of allFoldersArray) {
                if (folder.path === `/`) {
                    continue;
                }

                const parentFolder = allFoldersArray.find(f => f.path === folder.parent_path);

                if (parentFolder) {
                    parentFolder.children.unshift(folder);
                } else {
                    const folders = folder.parent_path.split(`/`);
                    folders.shift();
                    let currentFolder = allFolders[`/`];
                    let currentPath = ``;
                    for (const folderName of folders) {
                        currentPath += `/` + folderName;
                        const child = currentFolder.children.find(c => c.path === currentPath);
                        if (child) {
                            currentFolder = child;
                        } else {
                            const newFolder = {
                                id: currentPath,
                                name: folderName,
                                path: currentPath,
                                parent_path: currentPath.split(`/`).slice(0, -1).join(`/`),
                                children: [],
                                dragDisabled: true,
                                editNodeDisabled: true,
                                delNodeDisabled: true
                            };
                            currentFolder.children.unshift(newFolder)
                            currentFolder = newFolder;
                        }
                    }
                    currentFolder.children.unshift(folder);
                }
            }

            return new Tree([allFolders[`/`]]);
        }
    },
    activated() {
        this.unwatch = this.$store.watch(
            (state, getters) => getters.cluster,
            () => {
                this.init();
            }
        );

        this.init();
    },
    deactivated() {
        this.unwatch();
    },
    methods: {
        init() {
            this.showPasswords = false;
            this.secretsToImport = [];
            this.getSecrets();
        },
        async getSecrets() {
            this.loading = true;
            const resp = await Network.get(`/api/secrets/${this.$store.state.cluster}`)
            this.secrets = resp.data;

            this.$nextTick(() => {
                const expandTree = document.querySelector(`.vtl-icon-caret-right:not(.vtl-tree-margin .vtl-icon-caret-right)`);
                if (expandTree) {
                    expandTree.click();
                }
            });
            this.loading = false;
        },
        save(secret) {
            Network.post(`/api/secrets/save`, {
                cluster: this.$store.state.cluster,
                path: secret.path,
                value: secret.value
            }).then(() => {
                this.secretsEdited[secret.path] = false;
            })

        },
        addNode(node) {
            this.newSecret.name = ``;
            this.newSecret.value = ``;
            this.newSecret.parent_path = node.parent.path;
            this.newSecret.folder_name = ``;
            if (node.isLeaf) {
                this.newSecret.create_folder = false;
            } else {
                this.newSecret.create_folder = true;
            }
            this.secretCreationVisible = true;

            node.remove();
        },
        renameFolder(event) {
            if (event.eventType !== `blur`) {
                return;
            }

            const newPath = event.id.split(`/`).slice(0, -1).join(`/`) + event.newName;

            const allContainedSecrets = this.secrets.filter(s => s.path.includes(event.id))

            for (const secret of allContainedSecrets) {
                secret.path = secret.path.replace(event.id, newPath)
                secret.parent_path = secret.parent_path.replace(event.id, newPath)
            }
        },
        createNewSecret() {
            let parentPath = this.newSecret.parent_path;

            if (this.newSecret.create_folder) {
                const folders = this.newSecret.folder_name.split(`/`);
                let folderName = ``;
                for (const folder of folders) {
                    folderName += folder
                        .trim()
                        .toUpperCase()
                        .replace(/__/g, `_`)
                        .replace(/\s+/g, `_`)
                        .normalize(`NFD`).replace(/\p{Diacritic}/gu, ``)
                        .replace(/\W/g, ``);

                    folderName += `/`;
                }

                folderName = folderName.slice(0, -1);

                parentPath += `/` + folderName;
                parentPath = parentPath.replace(`//`, `/`);
            }

            const secretName = this.newSecret.name
                .trim()
                .toUpperCase()
                .replace(/__/g, `_`)
                .replace(/\s+/g, `_`)
                .normalize(`NFD`).replace(/\p{Diacritic}/gu, ``)
                .replace(/\W/g, ``);

            const secret = {
                parent_path: parentPath,
                path: (parentPath + `/` + secretName).replace(`//`, `/`),
                name: secretName,
                value: this.newSecret.value
            }

            this.secrets.unshift(secret);

            this.save(secret);

            this.secretCreationVisible = false;
        },
        getSecretFullName(secret) {
            return secret.path.slice(1).replace(/\//g, `__`);
        },
        deleteSecret(secret) {
            Network.delete(`/api/secrets/delete/${this.$store.state.cluster}/${this.getSecretFullName(secret)}`)
                .finally(() => {
                    this.getSecrets();
                });
        },
        copySecretName(secret) {
            this.copy(this.getSecretFullName(secret))
        },
        copy(text) {
            navigator.clipboard.writeText(text)
                .then(() => {
                    /* clipboard successfully set */
                }, () => {
                    /* clipboard write failed */
                });
        },
        previewImport(callback) {
            this.secretsToImport = [];
            Network.get(`/api/secrets/${this.clusterToImport}`)
                .then(resp => {
                    const otherClusterSecrets = resp.data;
                    for (const otherSecret of otherClusterSecrets) {
                        const secretAlreadyExists = this.secrets.find(s => s.path === otherSecret.path);

                        otherSecret.import = this.defaultImportOption;
                        if (!secretAlreadyExists) {
                            this.secretsToImport.push(otherSecret);
                        }
                    }

                    callback();
                });
        },
        importSecrets(callback) {
            let secrets = []
            for (const secret of this.secretsToImport) {
                if (secret.import === `do_not_import`) {
                    continue;
                }

                if (secret.import === `import`) {
                    secret.value = `<imported>`
                }

                secrets.push(secret);
            }

            Network.post(`/api/secrets/multi-save`, {
                cluster: this.$store.state.cluster,
                secrets: secrets
            }).then(() => {
                this.secrets = [...secrets, ...this.secrets];
                this.secretsToImport = [];
                this.$refs[`import-secrets-modal`].hide();
                callback();
            });
        },
        async downloadSecretsFile() {
            await this.getSecrets();

            let secretJSFile = {};
            for (const secret of this.secrets) {
                const fullName = this.getSecretFullName(secret);
                if (fullName.includes(`__`)) {
                    secretJSFile[fullName] = secret.value;
                }
            }

            const content = `module.exports = ` + JSON.stringify(secretJSFile, null, 4);

            const file = new Blob([content], {type: `text/plain:charset=UTF-8`});

            const a = document.createElement(`a`);
            a.setAttribute(`download`, `config_${this.$store.state.cluster}.js`);
            const href = URL.createObjectURL(file);
            a.href = href;
            a.setAttribute(`target`, `_blank`);
            a.click();
            URL.revokeObjectURL(href);
        },
        secretValueShouldWarn(secret) {
            switch (secret.name) {
                case `USE_FIREWALL`:
                    return this.currentCluster.env !== `live` && secret.value !== `yes`;
            }

            return false;
        }
    }
};
</script>


<style lang="scss" scoped>
.tree-container::v-deep .vtl-leaf-node .vtl-node-content {
    text-align: left;
    width: 100% !important;
}

.secret-value {
    resize: none;
    height: calc(1.5em + 0.75rem + 2px);
}

.blur {
    color: transparent;
    text-shadow: 0 0 8px black;

    &::selection {
        color: transparent
    }
}
</style>
